<template lang='pug'>
  v-footer.footer(height='auto')
    v-container.pa-0(fluid, grid-list-xl)
      v-container
        v-layout(row, wrap)
          v-flex.footer-col(xs12, md12, lg3)
            img.footer-logo(src=("https://res.cloudinary.com/sekuritim/image/upload/v1505842585/www/img/sekuritim_scaled.png"))
            .mt-4
          v-flex.footer-col(xs12, sm4, lg3)
          v-flex.footer-col(xs12, sm4, lg3)
            .footer-subtitle Hızlı Erişim
            ul.footer-links
              router-link(to='/newsletter', tag='li'): a Ücretsiz Deneyiniz
              router-link(to='/5651_Sayılı_Kanun', tag='li'): a Log Server
              li: a(href='https://shop.sekuritim.com' target = " ") Online Satış
              li: a(href='https://blog.sekuritim.com' target = " ") Nasıl Bağlanırım?
              li: a(href='https://blog.sekuritim.com' target = " ") Blog
              router-link(to='/about', tag='li'): a İletişim
          v-flex.footer-col(xs12, sm4, lg3)
            .footer-subtitle İletişim
            ul.footer-links
              li: a Fatih Cad. Nezihi Sk. 18 Ataşehir İstanbul
              li: a 0 850 460 22 21
              li: a info@sekuritim.com
              li: a(href='https://sekuritim.com') sekuritim.com
              li: a(href='https://blog.sekuritim.com' target=' ') Nasıl Bağlanırım?
              //li: a(href='https://blog.sekuritim.com') Blog
              //router-link(to='/about', tag='li'): a sekuritim.com
              //router-link(to='/newsletter', tag='li'): a Newsletter
      v-divider.mt-5
      v-container.footer-bar
        v-layout(row, wrap, align-center)
          // v-flex(xs12, md4)
          // div Released under the #[strong AGPL-v3 License]
          // div Copyright © 2017-{{currentYear}} #[a(href='https://www.requarks.io/', title='requarks.io') Requarks.io]
          v-flex.hidden-sm-and-down.text-xs-center(md4)
            <i class="fab fa-twitter-square"></i>
            a.mr-2(href='https://twitter.com/sekuritim' target=' '): font-awesome-icon(:icon="['fab', 'twitter-square']" size="2x")
            a.mr-2(href='https://www.facebook.com/sekuritim/' target=' '): font-awesome-icon(:icon="['fab', 'facebook-square']" size="2x")
            a.mr-2(href="https://www.linkedin.com/company/sekuritim/" target=' '): font-awesome-icon(:icon="['fab', 'linkedin']" size="2x")
            a.mr-2(href="https://www.instagram.com/sekuritim_bilisim/" target=' '): font-awesome-icon(:icon="['fab', 'instagram']" size="2x")
            a.mr-2(href="https://www.youtube.com/channel/UCnhH6GWR1XBG2fTWS4pnhUQ" target=' '): font-awesome-icon(:icon="['fab', 'youtube']" size="2x")
            a.mr-2(href="https://sekuritim-bilisim.business.site/" target=' '): font-awesome-icon(:icon="['fab', 'google']" size="2x")
          v-flex(xs12, md4)

</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'SiteFooter',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    stable: get('stable'),
    beta: get('beta')
  }
}
</script>

<style lang='scss'>
.theme--light.v-footer.footer {
  background-color: var(--v-greyish-base);
  background-image: url('../assets/shapes/shape1.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 40vw 120px;
}

.footer {
  padding-top: 75px;
  font-weight: 300;

  @media screen and (max-width: 599px) {
    padding-top: 30px;
  }

  &-logo {
    width: 275px;
    max-width: 500px;
  }
  &-subtitle {
    font-weight: 600;
    font-size: 18px;
  }
  &-links {
    list-style-type: none;
    padding: 0;

    li {
      display: block;
      margin: 16px 0;

      @media screen and (max-width: 599px) {
        margin: 8px 0;
      }

      a {
        color: var(--v-greyish-darken1);
        text-decoration: none;
        font-size: 16px;
        transition: color .4s linear;

        &:hover {
          color: var(--v-primary-base);
        }
      }
    }
  }
  &-col {
    @media screen and (max-width: 1264px) {
      text-align: center;
    }
  }
  &-bar {
    color: var(--v-greyish-darken1);

    @media screen and (max-width: 1264px) {
      text-align: center;
    }

    a {
      color: var(--v-greyish-darken1);
      text-decoration: none;
      transition: color .4s linear;

      &:hover {
        color: var(--v-primary-base);
      }
    }

    .flex:last-child {
      text-align: right;

      @media screen and (max-width: 1264px) {
        text-align: center;
      }
    }
  }
}
</style>
