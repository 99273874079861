import Vue from 'vue'
import './plugins/vuetify'
import './plugins/vue-axios'
import './plugins/vue-luxon'
import './styles/animate.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// import '@/styles/all.css';

new Vue({
  router,
  store,
  render: h => h(App),
  apolloProvider: createProvider(),
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
    // this.$store.dispatch('fetchGlobalStats', this.$apollo)
  }
}).$mount('#app')
